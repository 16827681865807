
  import { Component, Vue, Watch } from "vue-property-decorator";
  import { formatDate, formatTime } from "@/date-utils";
  import { ICertificate, ICertificatesWrapper } from "@/interfaces";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { dispatchAddNotification } from "@/store/main/actions";
  import { debounce } from "@/utils";

  const debounceSearchCertificates = debounce(async (component, token, params) => {
    const resp = await api.searchCertificates(token, params);
    component.certificatesResults = resp.data;
    component.loading = false;
  }, 300);

  @Component
  export default class AdminCertificates extends Vue {
    public headers = [
      {
        text: "First Name",
        sortable: true,
        value: "first_name",
        align: "left",
      },
      {
        text: "Last Name",
        sortable: true,
        value: "last_name",
        align: "left",
      },
      {
        text: "Type",
        sortable: true,
        value: "type",
        align: "left",
      },
      {
        text: "Effective Date",
        sortable: true,
        value: "effective_date",
        align: "left",
      },
      {
        text: "Expiration Date",
        sortable: true,
        value: "expiration_date",
        align: "left",
      },
      {
        text: "Created At",
        sortable: true,
        value: "creation_date",
        align: "left",
      },
    ];

    certificateTypes = [{ name: "MDTP", value: "mdtp" }];

    loading = false;
    options = {
      sortBy: ["effective_date"],
      sortDesc: ["true"],
      page: 1,
      itemsPerPage: 50,
    };
    searchQuery: string | null = null;
    certificatesResults: ICertificatesWrapper = {
      pagination: { total: 0 },
      certificates: [],
    };
    selected: ICertificate[] = [];
    dialog = false;
    effectiveDateMenu = false;
    expirationDateMenu = false;
    certificateType = "mdtp";
    effectiveDate: string | null = null;
    expirationDate: string | null = null;
    overrideExpiration = false;

    get certificates() {
      return this.certificatesResults.certificates;
    }

    get totalCertificates() {
      return this.certificatesResults.pagination.total;
    }

    async refreshData() {
      this.loading = true;
      const token = readToken(this.$store);

      const params = {
        limit: this.options.itemsPerPage,
        skip: this.options.itemsPerPage * (this.options.page - 1),
      };
      if (this.options.sortBy.length > 0) {
        params["sort_by"] = this.options.sortBy[0];
      }
      if (this.options.sortDesc.length > 0) {
        params["desc"] = !!this.options.sortDesc[0];
      }
      if (this.searchQuery) {
        params["query"] = this.searchQuery;
      }

      await debounceSearchCertificates(this, token, params);
    }

    @Watch("options")
    async onOptionsChange() {
      this.refreshData();
    }

    @Watch("searchQuery")
    async onSearchQueryChange() {
      this.options.page = 1;
      this.refreshData();
    }

    formatDate(date: string) {
      return formatDate(date, null);
    }

    formatTime(date: string) {
      return `${formatTime(date, null)}`;
    }

    @Watch("effectiveDate")
    onEffectiveDateChange(newEffectiveDate: string) {
      if (!this.overrideExpiration) {
        const parts = newEffectiveDate.split("-");
        const year = parseInt(parts[0], 10) + 3;
        parts.shift();
        this.expirationDate = `${year}-${parts.join("-")}`;
      }
    }

    resetDates() {
      const now = new Date();
      this.effectiveDate = now.toISOString().split("T")[0];
    }

    public async mounted() {
      this.resetDates();
      await this.refreshData();
    }

    async updateCertificates() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const token = readToken(this.$store);

      const data = {};
      if (this.effectiveDate) {
        data["effective_date"] = `${this.effectiveDate}T12:00`;
      }
      if (this.expirationDate) {
        data["expiration_date"] = `${this.expirationDate}T12:00`;
      }
      if (this.certificateType) {
        data["type"] = this.certificateType;
      }

      for (let i = 0; i < this.selected.length; i++) {
        const id = this.selected[i].id;
        await api.updateCertificate(token, id, data);
      }
      dispatchAddNotification(this.$store, {
        content: "Certificates Updated",
      });
      this.dialog = false;
      await this.refreshData();
      this.loading = false;
    }
  }
